.paymentRegisterContainer .wpwl-brand-card {
    display: none;
}

.paymentRegisterContainer .wpwl-group.wpwl-group-brand {
    display: none;
}

.paymentRegisterContainer .wpwl-group-billing {
    display: none;
}

.paymentRegisterContainer .wpwl-form-card {
    background-image: none;
    background-color: transparent;
    border-radius: none;
    margin: 0;
    box-shadow: none;
    border: none;
    padding: 0;
}

.paymentRegisterContainer .wpwl-form {
    max-width: 100%;
}

.paymentRegisterContainer .wpwl-group-cardNumber {
    width: 100%;
    padding-right: 0;
}

.paymentRegisterContainer .wpwl-group-expiry {
    width: 45%;
    padding-right: 32px;
}

.paymentRegisterContainer .wpwl-group-cardHolder {
    padding-right: 0;
}

.paymentRegisterContainer .wpwl-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #3400C8 !important;
    font-family: "Livvic";
}

.paymentRegisterContainer .total-processing-card-icons-container {
    position: relative;
}

.paymentRegisterContainer .total-processing-card-icons {
    position: absolute;
    right: 0;
    top: 4px;
}

.paymentRegisterContainer .wpwl-container-card {
    margin-top: 5px;
}

.paymentRegisterContainer .wpwl-group {
    margin-bottom: 6px;
}

.paymentRegisterContainer .wpwl-group-cardHolder {
    width: 100% !important;
    clear: both;

}

.paymentRegisterContainer .wpwl-control, .paymentRegisterContainer input.wpwl-control {
    background: white;
    border: none;
    box-shadow: none;
    border-radius: 5px;
    font-family: Arial;
    text-transform: none;
    font-size: 14px;
    height: 37px;
    padding: 10px 16px;
    color: rgba(0, 0, 0, 0.87);
}

.paymentRegisterContainer .totalprocessing-checkbox {
    display: flex;
    margin-top: 13px;
}

.paymentRegisterContainer .input-checkbox-totalprocessing {
    top: 0;
    left: 0;
    width: 100%;
    cursor: inherit;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 1;
}

.paymentRegisterContainer .wpwl-button-pay {
    border: none;
    height: 40px;
    position: relative;
    min-width: 64px;
    padding-left: 16px;
    padding-right: 13px;
    padding-top: 6px;
    padding-bottom: 6px;
    vertical-align: middle;
    margin: 0;
    font-weight: 700;
    background-color: #3400C8;
    align-items: center;
    display: inline-flex;
    width: 223px;
    float: left;
    margin-top: 80px;
    position: absolute;
}

.paymentRegisterContainer .wpwl-button-pay:hover {
    background-color: #3400C8;
}

.paymentRegisterContainer .wpwl-button-pay[disabled] {
    background-color: #3400C8;
}

.paymentRegisterContainer .wpwl-button-pay[disabled],
.paymentRegisterContainer .wpwl-button-pay[disabled]:hover,
.paymentRegisterContainer .wpwl-button-pay[disabled]:focus,
.paymentRegisterContainer .wpwl-button-pay[disabled]:active {
    background-color: #3400C8;

}

.paymentRegisterContainer .checkbox-input-payment-totalprocessing {
    margin-top: 0;
    margin-bottom: 62px;
}


@media (max-width: 768px) {
    .paymentRegisterContainer .card-icon-div-2 {
        margin-top: 8px !important;
    }

    .paymentRegisterContainer .wpwl-label {
        font-size: 14px;
    }

    .paymentRegisterContainer .wpwl-container-card {
        margin-top: 0px;
    }

    .paymentRegisterContainer .wpwl-group-expiry {
        width: 48%;
        padding-right: 2%;
    }

    .paymentRegisterContainer .wpwl-group-cvv {
        width: 48%;
        padding-left: 2%;
        float: right;
    }

    .paymentRegisterContainer .wpwl-button-pay {
        left: 50%;
        transform: translate(-50%, 0%);
        font-size: 15px;
    }

    .paymentRegisterContainer .checkbox-input-payment-totalprocessing {
        margin-bottom: 72px;
    }
}


@media (min-width: 769px) {}