.lp-header-button {
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 26px !important;
    text-transform: none !important;
    color: #9fa0a7 !important;
}

.lp-cta-header {
    text-transform: none !important;
    font-size: 15px !important;
    line-height: 28px;
    font-weight: 700 !important;
    white-space: nowrap;
    border: 2px solid #3400c8 !important;
    box-shadow: none !important;
    border-radius: 4px !important;
}

.lp-main-with-header {
    position: relative;
}

.lp-bg-1 {
    position: absolute;
    right: 0;
}
.lp-bg-2 {
    position: absolute;
    left: 0;
    top: 330px;
}

.lp-bg-3 {
    position: absolute;
    left: 0;
    top: -200px;
}

.lp-container-clients {
    position: relative;
    padding-top: 87px;
}

.lp-value-proposition {
    font-weight: 600;
    font-size: 38px;
    line-height: 50px;
    color: #3400c8;
}

.lp-main-content {
    padding-top: 183px;
    padding-bottom: 70px;
}

.lp-cta {
    text-transform: none !important;
    font-size: 15px !important;
    line-height: 46px;
    font-weight: 700 !important;
    white-space: nowrap;
    border: 2px solid #3400c8 !important;
    box-shadow: none !important;
    border-radius: 4px !important;
}

.lp-cta-secondary {
    border: 2px solid #0de3b0 !important;
}

.lp-cta-container {
    margin-top: 50px !important;
    margin-bottom: 14px !important;
}

.lp-cta-description {
    color: #9fa0a7;
    font-size: 18px;
    line-height: 26px;
}

.lp-section {
    padding-bottom: 70px;
}

.lp-section-service {
    padding-bottom: 85px;
}

.lp-section-title {
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: #717171;
    font-weight: 600;
    margin-bottom: 20px;
}

.lp-section-title-description {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #9fa0a7;
    font-weight: 400;
    margin-bottom: 40px;
}

.lp-section-title-description-white {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #ffffff;
    font-weight: 400;
    margin-bottom: 40px;
}

.lp-section-title-description2 {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #717171;
    font-weight: 400;
    margin-bottom: 60px;
}

.lp-phone-top-container {
    position: relative;
}

.lp-phone-top {
    max-width: 150%;
    margin-top: -100px;
    margin-bottom: -70px;
}

.lp-note-description {
    font-size: 18px;
    font-weight: 400;
    color: #9fa0a7;
    margin-top: 13px;
}

.lp-service-container {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    padding-right: 10px;
    padding-left: 10px;
}

.lp-service-title {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: #717171;
    margin-bottom: 15px;
    margin-top: 40px;
}

.lp-service-description {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #717171;
}

.lp-service-image {
    max-width: 100%;
}
.lp-service-image2 {
    margin-top: 19px;
}
.lp-service-image3 {
    margin-top: -19px;
}
.lp-service-image4 {
    margin-top: 19px;
}

.tool-section {
    background-color: #3400c8;
    color: #ffffff;
    padding-top: 53px;
    padding-bottom: 60px;
}

.tool-section .lp-section-title {
    color: #ffffff;
}

.lp-tool-img {
    margin-top: 68px;
    margin-bottom: 54px;
}

.lp-tool-title {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
    margin-bottom: 23px;
    text-align: center;
}

.lp-tool-description {
    text-align: center;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
}

.lp-linear-gradient {
    background: linear-gradient(359.16deg, rgba(13, 227, 176, 0.3) -32.61%, rgba(13, 227, 176, 0) 99.33%);
}

.lp-bold {
    font-weight: 600;
}

.lp-client-container {
    background: rgba(13, 227, 176, 0.1);
    border-radius: 10px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 40px;
}
.lp-client-title {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #3400c8;
    margin-bottom: 15px;
}

.lp-client-description {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #717171;
}

.lp-register-title {
    color: #0de3b0;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 15px;
}

.lp-register-description {
    font-size: 18px;
    color: #717171;
    text-align: center;
    line-height: 26px;
    margin-bottom: 20px;
}

.lp-register-button {
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 19px !important ;
    text-transform: none !important;
    z-index: 2 !important;
}

.lp-register-or {
    color: #9fa0a7;
    font-size: 30px;
    line-height: 36px;
    font-style: italic;
    font-weight: 300;
    margin-top: 80px;
}

.lp-container-register {
    position: relative;
}

.lp-section-register {
    z-index: 2;
}

.lp-bg-4 {
    position: absolute;
    right: 0;
    top: 288px;
    z-index: 1;
}

.lp-section-title-question {
    margin-top: 112px;
}

.lp-register-button-contact {
    text-align: center !important;
    font-size: 16px !important;
    line-height: 19px !important;
    text-transform: none !important;
    height: 58px !important;
    z-index: 2 !important;
}

.lp-container-cta {
    background-color: #3400c8;
    position: relative;
    overflow: hidden;
}

.lp-bg-5 {
    position: absolute;
    right: -180px;
    bottom: -180px;
    z-index: 1;
}
.lp-bg-6 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.lp-phone-cta {
    position: absolute;
    bottom: 0;
    z-index: 3;
}

.lp-section-cta-title {
    color: #0de3b0;
    font-weight: 600;
    font-size: 35px;
    line-height: 42px;
}

.lp-cpa-1 {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    margin-top: 41px;
}

.lp-cpa-2 {
    font-weight: 600;
    font-size: 46px;
    line-height: 55px;
}

.lp-cpa-3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #0de3b0;
}

.lp-cpa-description-container {
    margin-top: 23px !important;
    font-weight: 500;
    font-size: 25px;
    line-height: 35px;
}

.lp-cpa-description {
    margin-bottom: 15px !important;
}

.lp-cpa-button-2 {
    text-align: center !important;
    font-size: 16px !important;
    line-height: 19px !important;
    text-transform: none !important;
    height: 58px !important;
}

.big-icon {
    font-size: 2.1875rem !important ;
}

.lp-cpa-div-button {
    margin-top: 45px !important;
}

.lp-container-pros {
    position: relative;
    padding-top: 87px;
    background: linear-gradient(359.16deg, rgba(13, 227, 176, 0.3) -32.61%, rgba(13, 227, 176, 0) 99.33%);
}

.lp-pros-container {
    background: #ffffff;
    box-shadow: 7px 7px 37px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    overflow: hidden;
}

.lp-pros-image {
    max-width: 100%;
}

.lp-pros-description-container {
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-bottom: 10px !important;
    min-height: 252px !important;
}

.lp-section-title-pros {
    margin-bottom: 72px;
}

.lp-pros-title {
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    color: #0de3b0;
    margin-bottom: 10px;
}

.lp-pros-description {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: #717171;
    margin-bottom: 15px;
}

.lp-pros-button {
    text-transform: none !important;
    font-size: 16px !important;
    line-height: 19px !important;
    font-weight: 700 !important;
    z-index: 2 !important;
}

.lp-pros-image-container {
    height: 250px;
    background: linear-gradient(240.22deg, rgba(13, 227, 176, 0.5) -61.87%, rgba(255, 255, 255, 0.5) 129.18%);
    margin-bottom: 36px;
    overflow: hidden;
}

.lp-container-companies {
    position: relative;
    padding-top: 87px;
}

.lp-bg-7 {
    position: absolute;
    right: 0;
    top: -200px;
    z-index: 0;
}

.lp-partner-image {
    max-width: 100%;
    z-index: 2;
    position: relative;
}

.lp-full-height {
    height: 100%;
    z-index: 2;
}

.lp-testimonials-container {
    width: 80% !important;
    margin-top: 90px !important;
}

.lp-caroussel-container {
    position: relative;
    display: block;
}

.lp-testimonial-description {
    min-height: 218px;
    width: 90%;
    background: rgba(13, 227, 176, 0.1);
    border-radius: 10px;
    position: relative;
}

.lp-testimonial-desciprion-container {
    padding: 40px 100px 20px 100px;
}

.lp-testimonial-quote-left {
    position: absolute;
    left: 21px;
    top: 0px;
    font-weight: 600;
    font-size: 100px;
    line-height: 120px;
    color: #3400c8;
    font-family: Livvic;
}

.lp-testimonial-quote-right {
    position: absolute;
    right: 21px;
    bottom: 0px;
    font-weight: 600;
    font-size: 100px;
    line-height: 120px;
    color: #3400c8;
    transform: rotate(-180deg);
}

.lp-testimonial-comment {
    font-size: 18px;
    line-height: 25px;
    font-weight: 400;
    text-align: center;
    color: #3400c8;
}

.lp-testimonial-name {
    font-style: italic;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #3400c8;
    margin-top: 34px;
}

.lp-testimonial-arrow-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.lp-dot-container {
    margin-top: 20px !important;
}

.lp-dot-comment {
    background-color: #e8eaf0;
    height: 8px;
    width: 8px;
    cursor: pointer;
    border-radius: 8px;
}

.lp-dot-comment-active {
    background-color: #0de3b0;
    height: 8px;
    width: 8px;
    cursor: pointer;
    border-radius: 8px;
}

.lp-newsletter-button {
    font-size: 15px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    color: #0de3b0 !important;
    text-transform: none !important;
    height: 40px !important;
    width: 120px !important;
    border: 2px solid #0de3b0 !important;
}

.lp-newletter-input ::placeholder {
    font-style: italic;
    color: #ffffff;
    opacity: 1 !important ;
}

.lp-newsletter-button-container {
    width: 120px !important;
}

.go-top {
    position: fixed;
    cursor: pointer;
    bottom: -100px;
    right: 20px;
    color: #ffffff;
    background-color: #3400c8;
    z-index: 4;
    width: 45px;
    text-align: center;
    height: 45px;
    opacity: 0;
    visibility: hidden;
    border-radius: 50%;
    font-size: 22px;
    -webkit-transition: 0.9s;
    transition: 0.9s;
    overflow: hidden;
    -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}

.go-top.active {
    opacity: 1;
    visibility: visible;
    bottom: 20px;
}

.go-top:hover {
    background-color: #3400c8;
    color: #ffffff;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.lp-navbar {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 999;
    box-sizing: border-box;
    padding-bottom: 26px;
    display: block;
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.lp-navbar.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    background-color: #ffffff !important;
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-bottom: 26px;
}

.logo-lp {
    cursor: pointer;
}

.lp-go-to-top-icon {
    padding-top: 5px;
}

.lp-bg-white {
    background-color: #ffffff;
}

.lp-note-asterix {
    font-size: 11px;
    margin-top: 20px;
    color: #9fa0a7;
    font-weight: 300;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
}

.lp-trustpilot {
    margin-top: 30px;
    border: 1px dashed #c8d0f9 !important;
    border-radius: 1rem;
    padding: 20px;
    max-width: 500px;
}

.lp-reassurance-trustpilot {
    height: 117px;
}

.lp-pub-tv-button {
    cursor: pointer;
}

.lp-pub-tv-text {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #3400c8;
}
video-responsive {
    overflow: hidden;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 480px;
    width: 100%;
}

.lp-register-main-content{
    min-height: 100vh;
    background: linear-gradient(359.16deg, rgba(13, 227, 176, 0.7) -32.61%, rgba(13, 227, 176, 0) 99.33%);
}

.lp-register-bg-1{
    position: absolute;
    right: 0;
    bottom: 0;
}

.lp-register-bg-2{
    position: absolute;
    left:0;
    bottom: 0;
}


.lp-register-main-pannel{
    background: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    max-width: 1073px;
    margin: auto;
    padding-top: 55px;
    padding-left:78px;
    padding-right:69px;
    padding-bottom: 43px;
}

.lp-register-main-pannel-left{
    border-right: 1px solid #DDDDDD ;
    padding-right: 45px;
}

.lp-register-main-pannel-right{
    padding-left: 45px;
}

.lp-register-pannel-title{
    font-family: Livvic;
    font-weight: 600;
    font-size: 25px;
    line-height: 120.19%;
    color: #717171;
}

.label-reagular-input-register {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #3400c8;
    font-weight: 500;
    margin-top: 12px;
    margin-bottom: 5px;
}

.lp-register-fee{
    background: rgba(13, 227, 176, 0.1);
    border-radius: 4px;
    width: 100%;
    padding-left: 18px;
    padding-right: 20px;

}

.lp-register-fee-text{
    color:#717171;
    font-weight: 600;
    font-size: 16px;
    line-height: 141.69%;
}

.lp-register-fee-subtext{
    color:#717171;
    font-weight: 400;
    font-size: 12px;
    line-height: 141.69%;
}

.payment-label-2-register {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #3400C8;
}

.label-input-small-2-register{
    color: #9FA0A7;
    font-size: 11px;
    line-height: 13px;
    display: block;
    font-weight: 300;
}

.lp-register-fee-separator{
    width: 100%;
    height: 1px;
    background-color:#FFFFFF ;
}

@media (max-width: 768px) {
    .logo-lp {
        cursor: pointer;
        max-width: 120px;
    }
    .lp-bg-1 {
        top: 28px;
        right: -381px;
    }
    .lp-cta-container {
        margin-bottom: 19px !important;
    }
    .lp-value-proposition {
        text-align: center;
        font-size: 30px;
        line-height: 36px;
    }
    .lp-cta-description {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
    }
    .lp-main-content {
        padding-top: 129px;
    }
    .lp-phone-top {
        margin-top: 0px;
    }
    .lp-section-title {
        font-size: 25px;
        font-weight: 600;
        line-height: 30px;
        padding-right: 27px;
        padding-left: 27px;
    }
    .lp-section-title-description {
        font-size: 16px;
        line-height: 23px;
        font-weight: 400;
        padding-right: 27px;
        padding-left: 27px;
    }
    .lp-note-description {
        font-size: 18px;
        line-height: 26px;
        color: #9fa0a7;
    }
    .lp-service-title {
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        font-weight: 600;
        padding-right: 27px;
        padding-left: 27px;
    }
    .lp-service-description {
        font-size: 16px;
        line-height: 23px;
        font-weight: 400;
        padding-right: 27px;
        padding-left: 27px;
    }
    .lp-section-service {
        padding-bottom: 38px;
    }
    .lp-tool-img {
        margin-top: 60px;
        margin-bottom: 25px;
    }
    .lp-tool-title {
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 20px;
    }
    .lp-tool-description {
        font-size: 16px;
        line-height: 23px;
    }
    .lp-container-clients {
        padding-top: 38px;
    }
    .tool-section {
        padding-bottom: 95px;
    }
    .lp-client-title {
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 24px;
    }
    .lp-client-description {
        padding-left: 17px;
        padding-right: 17px;
    }
    .lp-section-register {
        padding-top: 25px !important;
    }
    .lp-register-or {
        margin-top: 30px;
        margin-bottom: 10px;
    }
    .lp-bg-5 {
        right: 0;
        bottom: 0;
        z-index: 0;
    }
    .lp-bg-6 {
        right: 0;
        bottom: 0;
        z-index: 0;
    }
    .lp-phone-cta {
        z-index: 0;
    }
    .lp-container-cta {
        padding-bottom: 202px;
    }
    .lp-section-cta-title {
        text-align: center;
        font-size: 25px;
        line-height: 31px;
        padding-right: 20px;
        padding-left: 20px;
    }
    .lp-cpa-1 {
        text-align: center;
        font-size: 25px;
        line-height: 31px;
    }
    .lp-cpa-2 {
        text-align: center;
    }
    .lp-cpa-3 {
        text-align: center;
    }
    .lp-cpa-description {
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        margin-bottom: 37px !important ;
        padding-left: 20px;
        padding-right: 20px;
    }
    .lp-cpa-description-icon {
        margin-bottom: 15px;
    }
    .lp-cpa-div-button {
        margin-top: 37px !important;
    }
    .lp-testimonials-container {
        width: 100% !important;
    }
    .lp-testimonial-icon-button {
        padding: 0 !important;
    }
    .lp-testimonial-desciprion-container {
        padding: 45px 20px 27px 20px;
    }
    .lp-testimonial-quote-left {
        font-size: 70px;
        left: 6px;
        top: -16px;
    }
    .lp-testimonial-quote-right {
        font-size: 70px;
        right: 6px;
    }
    .sc-dlfnuX {
        margin: 0 !important;
    }
    .lp-navbar.is-sticky {
        padding-bottom: 7px;
    }
    .lp-menu-mobile {
        width: 100vw !important ;
        background-color: #ffffff;
        margin-left: -16px;
        margin-right: -16px;
    }
    .lp-cta-header-mobile-menu {
        margin-top: 10px !important;
        margin-bottom: 28px !important;
    }
    .lp-trustpilot {
        max-width: 240px;
        margin: auto;
        margin-top: 8px;
        padding: 15px;
    }
    .lp-pub-tv-text {
        font-weight: 700;
        font-size: 25px;
        line-height: 30px;
        color: #3400c8;
    }
    .label-reagular-input-register {
        margin-top: 10px;
        font-size: 13px;
    }
    .payment-label-2-register {
      font-size: 14px;
    }

    .lp-register-main-pannel{
        padding-top: 25px;
        padding-left:30px;
        padding-right:30px;
        padding-bottom: 24px;
    }
    .lp-register-main-pannel-left{
        border-right: none ;
        padding-right: 0px;
    }
    
    .lp-register-main-pannel-right{
        padding-left: 0px;
    }
    
    .lp-register-pannel-title{
        font-family: Livvic;
        font-weight: 600;
        font-size: 21px;
        line-height: 120.19%;
        color: #717171;
    }

    .lp-register-seprator {
        width:100%;
        margin-left: 20px;
        margin-right: 20px;
        background-color:  #DDDDDD;
        height: 1px;
        margin-top: 16px;
        margin-bottom: 16px;

    }

    .lp-register-pannel-title-right{
        font-size: 16px;
    }
    

}

@media (min-width: 769px) {
    .main-content {
        padding-left: 0;
        padding-right: 0;
    }
    .lp-service-container {
        min-height: 490px;
    }
    .lp-client-container {
        min-height: 395px;
    }
    .lp-phone-cta {
        right: -220px;
    }
    .lp-cpa-description-icon {
        margin-right: 16px;
    }
}

@media (min-width: 1280px) {
    .lp-widther-section {
        max-width: 1500px !important;
    }
    .lp-phone-cta {
        right: -50px;
    }
}
